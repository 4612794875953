.preview_wrapper {
    display: flex;
    justify-content: center;
    background: linear-gradient(to right, #ffffff 50%, #e8e8e8);
}

.preview_sub_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1440px;
    padding: 0 70px;
}

.preview_title {
    flex: 1;
}

.preview_info {
    font-size: var(--previewInfoFontSize);
    font-weight: bold;
    color: var(--greyBrown);
}

.component_title {
    font-size: var(--idFontSize);
    font-weight: 500;
    padding-right: 10%;
    font-family: var(--sectionTitleFontFamily);
}

.icon {
    max-width: 80%;
    max-height: 560px;
}

.preview_icon {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 2% 0;
}

@media only screen and (min-width: 1440px) {
    .preview_wrapper {
        margin-right: 0;
    }
}

@media only screen and (max-width: 768px) {
    .preview_sub_wrapper {
        display: block;
        margin-right: 0;
        padding: 15px 15px 0 15px;
    }

    .preview_wrapper {
        background: none;
    }

    .preview_title {
        display: flex;
        flex-direction: column;
    }

    .preview_info {
        font-size: var(--previewInfoFontSize);
        font-weight: bold;
        color: var(--greyBrown);
    }

    .component_title {
        font-size: var(--idFontSize);
        font-weight: 500;
        padding-right: 10%;
    }

    .preview_icon {
        background: none;
        display: flex;
        justify-content: center;
        padding: 10% 0 10% 0;
        background: linear-gradient(to bottom, #ffffff, #e8e8e8);
        margin: 0% -15px -15px -15px;
    }

    .icon {
        width: 80%;
    }
}
