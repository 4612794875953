.selectbar_wrapper {
    border-top: 1px solid var(--ice);
    border-bottom: 1px solid var(--ice);
    margin: 2% 0 5% 0;
    background-color: rgba(255, 255, 255, 0);
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 3;
    background-color: white;
}

.selectbar_menu {
    color: var(--marine) !important;
    font-size: var(--selectMenuFontSize) !important;
}

.selectbar_form {
    width: 100%;
    padding: 1% 15px !important;
}

.selectbar_select {
    color: var(--marine) !important;
    font-size: var(--selectMenuFontSize) !important;
}
