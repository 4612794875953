.accordion-item {
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
    border-top: 1px solid var(--ice) !important ;
    transition: 2s !important;
    padding-top: 8%;
}
button.accordion-button:focus {
    background-color: white !important;
}

button.accordion-button {
    background-color: white !important;
    box-shadow: none !important;
    // button titel and arrow to style
    padding: 2% 0 2% 0 !important;
    font-size: var(--sectionTitleFontSize);
    font-family: var(--sectionTitleFontFamily);
    font-weight: 500;
    transition: 0.5s;
}
.accordion-body {
    // button titel and arrow to style
    padding: 0 0 5% 0 !important;
}

button.accordion-button:not(.collapsed) {
    padding: 2% 0 4% 0 !important;
    color: #000000;
}

button.accordion-button:after {
    background-image: url("../../assets/forward.svg") !important;
}

button.accordion-button:not(.collapsed):after {
    background-image: url("../../assets/forward.svg") !important;
    transform: rotate(90deg) !important;
}

@media only screen and (min-width: 1200px) {
    .accordion-item {
        padding-top: 4%;
    }
}
@media only screen and (min-width: 1000px) {
    .accordion-item {
        padding-top: 4%;
    }
}

@media only screen and (max-width: 768px) {
    button.accordion-button {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: white !important;
        margin: 5% 0 0 0;
    }

    button.accordion-button::after {
        margin-right: auto;
        margin-left: auto;
        margin-top: 40px;
        margin-bottom: 30px;
    }

    .accordion-item {
        margin-bottom: 10%;
        padding-top: 0%;
    }
}
@media only screen and (max-width: 576px) {
    .accordion-item {
        padding-top: 3%;
    }
    button.accordion-button::after {
        margin-top: 50px;
        margin-bottom: 40px;
    }
}
