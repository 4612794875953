.main_wrapper > .language_wrapper,
.header_wrapper,
.navbar_wrapper {
    margin: auto;
    max-width: 1440px;
    padding: 0 70px;
}

@media only screen and (max-width: 768px) {
    .main_wrapper > .language_wrapper,
    .header_wrapper,
    .navbar_wrapper {
        padding: 15px 15px 0 15px;
    }
}
