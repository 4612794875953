.header_wrapper {
    margin: 0;
    padding-top: 0 !important;
}

.header_logo {
    width: 227px;
}

@media only screen and (max-width: 768px) {
    .header_logo {
        width: 178px;
    }
}
