.documents_wrapper {
    display: flex;
    flex-direction: row;

    svg.MuiSelect-icon {
        margin-right: 10px !important;
    }
}

.language_select {
    padding-top: 1%;
    width: 30%;
}

.document_list {
    width: 70%;
}

.document_item_wrapper {
    padding-left: 14%;
    flex: 2;
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--lightPink);
}
.document_item_wrapper:last-of-type {
    border-bottom: 1px solid var(--lightPink);
}

.doc_general_part {
    flex: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    // Important for the other compoinent
    padding-right: 5%;
}

.clicked_doc_general_part {
    margin-top: 2%;
}

.doc_general_text {
    font-size: var(--keyFontSize);
    font-weight: 500;
    margin: 0;
    padding-right: 5%;
    font-family: var(--sectionTitleFontFamily);
}

.acrobat_icon {
    color: var(--softBlue);
    width: var(--acrobatFontSize) !important;
    height: var(--acrobatFontSize) !important;
}

.doc_link_part {
    flex: 3;
    background-color: var(--valueBackground);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5% 0 5% 2%;
    padding: var(--sectionInlineSpace);
    word-break: break-word;
}

.download_icon_wrapper {
    margin: -3% 0 3% 0;
}

.download_icon {
    opacity: 0.2;
}

.doc_link {
    text-decoration: none;
    color: var(--greyBrown);
    font-size: var(--valueFontSize);
    display: flex;
    flex-direction: row;
    padding-right: 10%;
    justify-content: space-between;
    align-items: center;
    word-break: break-word;
}

.doc_link:focus {
    color: var(--greyBrown);
}
.doc_link:hover {
    color: var(--softBlue);
}

.doc_link_icon_wrapper {
    margin-left: 5%;
}

.doc_link_icon {
    color: var(--marine);
}

.doc_lang_label {
    font-size: var(--languageSelectLabelFontSize) !important;
    color: rgb(0, 0, 0) !important;
}
.doc_lang_select {
    opacity: 0.5 !important;
    height: 100% !important;
    width: 100% !important;
}

.doc_lang_form {
    max-width: 90%;
}

@media only screen and (max-width: 768px) {
    .documents_wrapper {
        flex-direction: column;
    }

    .language_select {
        width: 100%;
        margin-bottom: 10%;
    }

    .document_list {
        width: 100%;
    }

    .doc_lang_form {
        width: 100%;
        max-width: 100%;
    }

    .document_item_wrapper {
        padding-left: 0;
        display: flex;
        flex-direction: column;
        border-top: 1px solid var(--lightPink);
    }

    .doc_general_part {
        padding: 10% 0 3% 0;
    }

    .clicked_doc_general_part {
        margin-top: 0;
    }

    .doc_general_text {
        padding-left: 3%;
    }

    .doc_link_part {
        padding: 5% 0 5% 3%;
    }

    .download_icon_wrapper {
        margin: 0 0 2% 0;
    }

    .doc_select_menu {
        font-size: var(--selectMenuFontSize) !important;
    }

    .document_item_wrapper:first-of-type {
        border-top: none;
    }
}
