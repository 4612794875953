.products_attributes_wrapper {
    margin-left: 30%;
}
.single_attribute_wrapper {
    display: flex;
    flex-direction: row;
    padding-left: 14%;
    border-top: 1px solid var(--lightPink);
}

.single_attribute_wrapper:last-of-type {
    border-bottom: 1px solid var(--lightPink);
}

.attribute_title {
    flex: 2;
    padding-right: 5%;
    font-size: var(--keyFontSize);
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
    word-break: break-word;
    font-family: var(--sectionTitleFontFamily);
}
.attribute_value {
    flex: 3;
    background-color: var(--valueBackground);
    color: var(--greyBrown);
    font-size: var(--valueFontSize);
    padding: var(--sectionInlineSpace);
    margin: 0;
    word-break: break-word;
}

@media only screen and (max-width: 768px) {
    .products_attributes_wrapper {
        margin-left: 0;
    }

    .single_attribute_wrapper {
        display: flex;
        flex-direction: column;
        padding-left: 0;
    }
    .attribute_title {
        padding: 10% 0 3% 2%;
    }
    .single_attribute_wrapper:first-of-type {
        border-top: none;
        .attribute_title {
            padding: 0% 0 3% 2%;
        }
    }
}
