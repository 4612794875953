.spare_part_wrapper {
    margin-left: 30%;
}

.spare_item {
    display: flex;
    flex-direction: row;
    border-top: 1px solid var(--lightPink);
    padding-left: 14%;
}

.spare_item:last-of-type {
    border-bottom: 1px solid var(--lightPink);
}

.attribute_quantity {
    flex: 2;
    padding-right: 5%;
    font-size: var(--keyFontSize);
    font-weight: 500;
    margin: 0;
    display: flex;
    align-items: center;
    font-family: var(--sectionTitleFontFamily);
}
.spare_part_value {
    background-color: var(--valueBackground);
    flex: 3;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--greyBrown);
    font-size: var(--valueFontSize);
    padding: var(--sectionInlineSpace);
}

.attribute_type {
    flex: 1;
    margin: 0;
}

.attribute_position_wrapper {
    flex: 1;
    margin: 0;
    position: relative;
}

.first_value_item {
    padding: 15% 0 5% 2%;
    position: relative;
}

.first_quantity_item {
    padding-top: 10%;
}

.attribute_position_label {
    font-size: var(--positionFontSize);
    font-weight: bold;
    position: absolute;
    margin-top: -35%;
}

.attribute_position {
    margin: 0;
}

@media only screen and (max-width: 768px) {
    .spare_part_wrapper {
        margin-left: 0;
    }

    .spare_item {
        flex-direction: column;
        padding-left: 0;
    }

    .attribute_quantity {
        padding-right: 0;
        padding: 10% 0 3% 2%;
    }

    .attribute_position_wrapper {
        position: initial;
    }

    .first_value_item {
        padding: 5% 0 5% 2%;
        position: initial;
    }

    .attribute_position_label {
        font-size: var(--positionFontSize);
        font-weight: bold;
        position: unset;
        margin-top: 0;
    }
    .spare_item:first-of-type {
        border-top: none;
        .attribute_quantity {
            padding: 0% 0 3% 2%;
        }
    }
}
