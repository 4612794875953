.footer_wrapper {
    width: 100%;
    margin-top: 8%;
    padding-bottom: 10%;
    background-image: url("../../assets/footer_net.svg");
    background-repeat: no-repeat;
    background-size: 50%;
    background-color: #f5f5f5;
}

.footer_title_wrapper {
    background-image: linear-gradient(94deg, #0099d6, #081d41 49%, #052a68 104%);
    color: white;
}

.footer_title_sub_wrapper {
    padding: clamp(12px, 3%, 40px) 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: white;
    max-width: 1440px;
    margin: auto;
}

.footer_title {
    flex: 1;
    width: 100px;
}

.footer_icon_section {
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 5%;
}

.social_icon {
    font-size: var(--footerTitleFontSize);
    color: white;
}
.footer_logo_part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 70px;
    max-width: 1440px;
    margin: auto;
    margin-top: clamp(12px, 10%, 50px);
}
.footer_logo {
    width: clamp(180px, 40%, 230px);
    max-width: 60%;
    margin: 0% 0 10% 0;
}
.icon_up_wrapper {
    width: 2rem;
    height: 2rem;
    aspect-ratio: 1;
    margin: 0% 0 10% 0;
}
.icon_up_link {
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.icon_up_image {
    width: 45px;
    height: 45px;
}

.footer_links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 70px;
    max-width: 1440px;
    margin: auto;
}
.footer_links > * {
    margin-right: 2%;
    text-decoration: none;
    color: var(--marine);
    font-size: var(--footerLinkFontSize);
}

.copy_right {
    order: 1;
    margin-left: auto;
    margin-right: 0;
}

@media only screen and (min-width: 1440px) {
    .footer_wrapper {
        margin: 8% 0px 0 0px;
    }
}

@media only screen and (max-width: 1200px) {
    .footer_wrapper {
        background-size: 75%;
    }

    .footer_links {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .footer_links > * {
        margin-bottom: 2%;
    }

    .copy_right {
        order: 0;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 8%;
    }
}

@media only screen and (max-width: 1000px) {
    .footer_wrapper {
        background-size: 100%;
    }

    .footer_title {
        flex: 1.5;
    }
}

@media only screen and (max-width: 768px) {
    .footer_wrapper {
        background-size: 100%;
        margin: 20% 0 0 0;
        padding-bottom: 20%;
    }

    .footer_title_sub_wrapper {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 3% 15px;
    }

    .footer_title {
        margin: 3%;
        width: clamp(2px, 33%, 120px);
    }

    .footer_logo_part {
        padding: 0 15px;
    }

    .footer_icon_section {
        margin-top: 5%;
        width: 100%;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .footer_links {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 576px) {
    .footer_wrapper {
        background-size: 200%;
    }

    .footer_logo {
        margin: 0% 0 20% 0;
    }

    .icon_up_wrapper {
        margin: 0% 0 20% 0;
    }
}
