.language_wrapper {
    text-align: end;
    margin-top: 1%;
}

@media only screen and (max-width: 768px) {
    .language_wrapper {
        margin-top: 0;
    }
    .language_menu {
        font-size: var(--selectMenuFontSize) !important;
    }
}
