.contact_wrapper {
    margin-top: 2%;
    padding-top: 9%;
}
.contact_title {
    font-size: var(--sectionTitleFontSize);
    font-family: var(--sectionTitleFontFamily);
    font-weight: 500;
}

.contact_text_1 {
    margin-top: 3%;
    margin-left: 45%;
    color: var(--lightGreyBlue);
    font-size: var(--keyFontSize);
}

.footer_last_part {
    padding-left: 50%;
}

.contact_text_2 {
    font-size: var(--valueFontSize);
    margin-bottom: 12%;
}

.contact_sections {
    display: flex;
    flex-direction: row;
    gap: 5%;
}

.share {
    cursor: pointer;
    margin-top: 5%;
    width: 47.5%;
    max-width: 310px;
    aspect-ratio: 31/19;
    display: flex;
    flex-direction: row;
    background-color: #052a68;
}

.share_text {
    flex: 1;
    color: white;
    display: flex;
    flex-direction: column;
}

.share_title {
    flex: 2;
    display: flex;
    align-items: end;
    margin-left: 16%;
    font-size: var(--valueFontSize);
    font-weight: 500;
}

.share_section_icon {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 16%;
}

.share_icon_wrapper {
    flex: 1;
    height: 100% !important;
    overflow: hidden;
}

.share_icon {
    width: 150%;
    color: rgb(255, 255, 255) !important;
    height: 145%;
    transform: rotate(180deg);
    margin-top: -28%;
    margin-left: -19%;
}

.support,
.sales {
    cursor: pointer;
    width: 50%;
    max-width: 310px;
    aspect-ratio: 31/19;
    background-color: #eaebeb;
    display: flex;
    flex-direction: column;
    padding-right: 3%;
}

.support_title,
.sales_title {
    flex: 2;
    margin-left: 8%;
    display: flex;
    align-items: end;
    font-size: var(--valueFontSize);
    font-weight: 500;
}

.support_section_icon,
.sales_section_icon {
    flex: 1;
    margin-left: 8%;
    color: #052a68;
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 1200px) {
    .contact_wrapper {
        padding-top: 4%;
    }
}
@media only screen and (min-width: 1000px) {
    .contact_wrapper {
        padding-top: 6%;
    }
}

@media only screen and (max-width: 940px) {
    .share,
    .support,
    .sales {
        width: 48%;
        aspect-ratio: 1/1;
    }

    .share_icon {
        margin-top: -85%;
    }
}

@media only screen and (max-width: 768px) {
    .contact_wrapper {
        padding-top: 7%;
    }
    .contact_sections {
        margin-left: 0;
    }

    .footer_last_part {
        padding-left: 0%;
    }

    .contact_text_1 {
        margin-left: 0%;
        margin-top: 8%;
    }
    .contact_text_2 {
        margin: 0 5%;
        margin-bottom: clamp(5%, 15%, 80px);
    }
}

@media only screen and (max-width: 576px) {
    .contact_wrapper {
        padding-top: 10%;
    }
}
