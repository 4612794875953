.general_wrapper {
    margin-left: 40%;
}

.article_description {
    font-size: var(--keyFontSize);
    color: var(--lightGreyBlue);
}

.general_item_key {
    font-size: var(--keyFontSize) !important;
    font-weight: 500;
    margin: 0;
    font-family: var(--sectionTitleFontFamily);
}

.general_item_key {
    font-size: var(--valueFontSize);
}

@media only screen and (max-width: 768px) {
    .general_wrapper {
        margin-left: 0;
    }
}
