@font-face {
    font-family: "NotoSans-Regular";
    src: url(./fonts/NotoSans-Regular.eot);
    src:
        url(./fonts/NotoSans-Regular.eot?#iefix) format("embedded-opentype"),
        url(./fonts/NotoSans-Regular.woff2) format("woff2"),
        url(./fonts/NotoSans-Regular.woff) format("woff"),
        url(./fonts/NotoSans-Regular.ttf) format("truetype"),
        url(./fonts/NotoSans-Regular.otf) format("otf");
    font-display: swap;
}
@font-face {
    font-family: "NotoSans-Medium";
    src: url(./fonts/NotoSans-Medium.eot);
    src:
        url(./fonts/NotoSans-Medium.eot?#iefix) format("embedded-opentype"),
        url(./fonts/NotoSans-Medium.woff2) format("woff2"),
        url(./fonts/NotoSans-Medium.woff) format("woff"),
        url(./fonts/NotoSans-Medium.ttf) format("truetype"),
        url(./fonts/NotoSans-Medium.otf) format("otf");
    font-display: swap;
}

body > * {
    font-family: "NotoSans-Regular";
}

:root {
    --ice: #eaebeb;
    --greyBrown: #555555;
    --lightGreyBlue: #b4babe;
    --lightPink: #cccccc;
    --valueBackground: #f4f5f5;
    --softBlue: #66b0e2;
    --marine: #052a68;

    --previewInfoFontSize: clamp(12px, 1.1vw, 20px);
    --selectMenuFontSize: clamp(14px, 3vw, 16px);
    --idFontSize: clamp(36px, 4.2vw, 60px);
    --sectionTitleFontSize: clamp(26px, 4vw, 38px);
    --sectionTitleFontFamily: "NotoSans-Medium";
    --acrobatFontSize: clamp(38px, 4vw, 68px);

    --keyFontSize: clamp(18px, 1.5vw, 24px);
    --valueFontSize: 16px;
    --footerTitleFontSize: clamp(18px, 2.5vw, 22px);
    --footerLinkFontSize: 14px;
    --languageSelectLabelFontSize: 14px;
    --positionFontSize: 12px;

    --sectionInlineSpace: clamp(2px, 5%, 40px) 0 clamp(2px, 5%, 40px) 2%;
}

@media only screen and (max-width: 768px) {
    :root {
        --previewInfoFontSize: 12px;
    }
}

@media only screen and (max-width: 576px) {
    :root {
        --idFontSize: clamp(30px, 7vw, 36px);
    }
}
