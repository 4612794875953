.navbar_header {
    display: flex;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: -5px;
    z-index: 3;
    height: 70px;
    min-height: 70px;
    background-color: #fff;
    margin-bottom: 3%;
}

.active {
    font-weight: 500;
    color: var(--marine);
    text-decoration: none;
}
.active:hover {
    color: var(--marine);
}

.navbar_list {
    cursor: pointer;
    color: var(--lightPink);
    font-size: var(--footerLinkFontSize);
    margin-right: 2%;
}

.navbar_list:hover {
    text-decoration: none;
    font-weight: 400;
}
